var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.apiLoading)?_c('div',{staticClass:"loading"},[_vm._v("\n        Loading…\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column flex-lg-row h-lg-full navOutDiv"},[_c('Sidebar'),_vm._v(" "),_c('div',{staticClass:"h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll"},[_c('main',{staticClass:"py-6"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-sm-11 col-12 mb-4 mb-sm-0 basic-info"},[_c('div',{staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"com-set-wrap",staticStyle:{"background-color":"white","margin":"0px 27px","border-radius":"15px"}},[_c('header',{staticClass:"bg-surface-primary border-bottom pt-6 profile-text-wrap",staticStyle:{"margin":"15px","padding-bottom":"50px"}},[_c('div',[_c('form',{staticClass:"d-block",attrs:{"enctype":"multipart/form-data"}},[_c('div',{staticStyle:{"padding":"15px"}},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-4 pb-2"},[_c('div',{staticClass:"form-outline form-white"},[_c('p',{staticClass:"communicationFormLabel"},[_vm._v("\n                                                            Title*\n                                                        ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:'title',staticClass:"form-control userForm form-control-lg inputOrange",class:{
                                                                'is-invalid': _vm.errors.has('title'),
                                                            },attrs:{"type":"text","name":'title',"placeholder":"Title"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}})])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-4 pb-2"},[_c('div',{staticClass:"form-outline form-white"},[_c('p',{staticClass:"communicationFormLabel"},[_vm._v("\n                                                            Description*\n                                                        ")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:'description',staticClass:"form-control textAreaForm form-control-lg inputOrange",class:{
                                                                'is-invalid': _vm.errors.has('description'),
                                                            },attrs:{"type":"text","rows":"4","cols":"50","name":'description',"placeholder":"Description"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing)return;_vm.description=$event.target.value}}})])])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-4 pb-2"},[_c('div',{staticClass:"form-outline form-white d-flex flex-row-reverse"},[_c('button',{staticClass:"platFormCardButton",staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.faqCreate()}}},[_vm._v(" Save ")])])])])])])])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-6 col-12 mb-4 mb-sm-0"},[_c('div',{staticClass:"card-header",staticStyle:{"background":"none","border":"none"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("Create FAQ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between align-items-center mb-5"},[_c('h1',[_vm._v("Details")])])
}]

export { render, staticRenderFns }